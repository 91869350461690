@use "../mixins/breakpoint";

.work-principles {
    padding-top: 35px;
    background-color: #fff;

    h3 {
        text-align: center;
        color: #1c1738;
    }

    ul {
        padding-left: 0;
        margin-bottom: 35px;

        li {
            display: table;
            font-size: 0;

            img {
                display: table-cell;
                margin-right: 10px;
                max-width: 48px;
                vertical-align: top;
            }

            p {
                display: table-cell;
                width: 100%;
                padding: 0;
                vertical-align: middle;
                color: #000;
            }
        }
    }

    .container-wrapper {
        padding-top: 40px;
        padding-bottom: 40px;
        background-color: #1c1738;

        .team-item {
            padding-bottom: 15px;
            padding-right: 20px;
            padding-left: 20px;

            h2 {
                padding-top: 8px;
                padding-bottom: 6px;
                border-bottom: 1px solid rgba(#000000, 0.2);
                font-size: 52px;
                font-weight: 500;
                line-height: 1.23;
                text-align: center;
            }

            p {
                text-align: center;
            }
        }
    }

    @include breakpoint.breakpoint(medium) {
        ul {
            li {
                p {
                    font-size: 16px;
                }
            }
        }
    }


    @include breakpoint.breakpoint(extra-small) {
        ul {
            li {
                margin-top: 45px;
                text-align: center;

                img {
                    display: inline-block;
                }

                p {
                    display: block;
                    margin-top: 15px;
                    font-size: 14px;
                }
            }
        }

        .container-wrapper {
            .team-item {
                h2 {
                    font-size: 48px;
                }
            }
        }
    }


    @include breakpoint.breakpoint(mobile) {
        h2 {
            margin-bottom: 0;
        }

        ul {
            li {
                margin-top: 20px;

                p {
                    font-size: 14px;
                }
            }
        }

        .container-wrapper {
            .team-item {
                h2 {
                    font-size: 42px;
                }
            }
        }
    }
}