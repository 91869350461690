@use "../mixins/breakpoint";

body {
    &.left-shift {
        width: 100%;
        margin-left: 265px;
        transition-property: all;
        transition-duration: 400ms;

        .contact-row {
            display: none;
        }

        .navbar-default {
            &.fixed-menu {
                left: 265px;
                transition-property: all;
                transition-duration: 400ms;
            }

            .navbar-header {
                .navbar-toggle {
                    .icon-bar {
                        background-color: #d4bb68;

                        &:nth-child(2) {
                            position: absolute;
                            transform: rotate(45deg);
                        }

                        &:nth-child(3) {
                            background-color: transparent;
                        }

                        &:last-child {
                            position: absolute;
                            transform: rotate(-45deg);
                        }
                    }
                }
            }

            .show-menu {
                position: fixed;
                top: 0;
                left: -265px;
                height: 100vh;
                margin-left: 265px;
                transition-property: all;
                transition-duration: 400ms;
            }
        }

        @include breakpoint.breakpoint(small) {
            #navbar-collapse {
                margin-left: 265px;
            }
        }
    }
}