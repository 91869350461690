.backtop {
    border-radius: 50%;
    background-color: #1a1a1a;
    padding: 10px 12px;
    width: 50px;
    height: 50px;
    position: fixed;
    bottom: 10px;
    right: -60px;
    z-index: 100;
    transition-property: all;
    transition-duration: 300;
    opacity: 0.7;

    .fa-chevron-up {
        font-size: 24px;
        opacity: 0.7;
    }
}

.islive {
    right: 15px;
    bottom: 45px;
}