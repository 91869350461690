@use "../mixins/breakpoint";

.about-us {
    position: relative;
    height: 80vh;

    .main-image {
        position: absolute;
        z-index: -1;
        overflow: hidden;
        width: 100%;
        height: 80vh;
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../img/background-image.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        transform: translateY(0);
    }

    .wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        max-width: 760px;
        width: 100%;
        padding: 30px;
    }

    @include breakpoint.breakpoint(mobile-landscape) {
        height: 100vh;

        .main-image {
            height: 100vh;
        }
    }


    @include breakpoint.breakpoint(mobile) {
        .wrapper {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
}