@use "../mixins/breakpoint";

.team {
    padding-bottom: 80px;
    text-align: center;
    background-color: #1c1738;

    .description {
        max-width: 560px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 105px;
        padding-top: 75px;

        h3 {
            font-family: 'PT Sans', sans-serif;
            text-align: center;
        }
    }

    @include breakpoint.breakpoint(small) {
        .description {
            margin-bottom: 30px;
        }
    }


    @include breakpoint.breakpoint(extra-small) {
        padding-left: 20px;
        padding-right: 20px;

        .description {
            margin-bottom: 20px;
            padding-top: 50px;
        }
    }


    @include breakpoint.breakpoint(mobile) {
        .description {
            margin-bottom: 15px;
            padding-top: 30px;
        }
    }
}