@use "mixins/breakpoint";

$baseFont: "Roboto", sans-serif;

body {
    padding: 0;
    margin: 0;
    position: relative;
    overflow-x: hidden;
    transition-property: all;
    transition-duration: 400ms;

    h1, h2, h3, h4, h5, h6 {
        margin-top: 0;
        font-family: $baseFont;
        text-align: left;
        color: #ecd78c;
    }

    h1 {
        text-transform: uppercase;

        @include breakpoint.breakpoint(extra-small) {}
    }

    h2 {
        margin-bottom: 0;
        font-size: 62px;
        font-weight: 700;
        line-height: 1.45;

        @include breakpoint.breakpoint(medium) {
            font-size: 58px;
        }


        @include breakpoint.breakpoint(small) {
            font-size: 48px;
        }


        @include breakpoint.breakpoint(extra-small) {
            font-size: 46px;
        }


        @include breakpoint.breakpoint(mobile-landscape) {
            font-size: 38px;
        }


        @include breakpoint.breakpoint(mobile) {
            font-size: 38px;
        }
    }

    h3 {
        margin-bottom: 40px;
        font-size: 36px;
        font-weight: 700;
        line-height: 1.23;

        @include breakpoint.breakpoint(small) {
            font-size: 34px;
        }


        @include breakpoint.breakpoint(extra-small) {
            margin-top: 30px;
            font-size: 32px;
        }


        @include breakpoint.breakpoint(mobile-landscape) {
            font-size: 26px;
        }


        @include breakpoint.breakpoint(mobile) {
            margin-top: 15px;
            margin-bottom: 10px;
            font-size: 26px;
        }
    }

    h4 {
        margin-bottom: 14px;
        font-family: 'Raleway', sans-serif;
        font-weight: 700;
        font-size: 14px;
        text-transform: uppercase;
        color: #9da5b9;
    }

    h5 {
        margin-top: 5px;
        margin-bottom: 0;
        font-size: 16px;
        font-weight: bold;
    }

    h6 {}

    p {
        padding-top: 8px;
        padding-bottom: 6px;
        font-family: $baseFont;
        font-size: 18px;
        font-weight: 300;
        line-height: 1.55;
        color: #ffffff;

        @include breakpoint.breakpoint(small) {
            font-size: 17px;
        }


        @include breakpoint.breakpoint(mobile-landscape) {
            font-size: 16px;
        }


        @include breakpoint.breakpoint(mobile) {
            font-size: 16px;
        }
    }

    img {
        max-width: 100%;
        display: inline-block;
    }

    a {
        display: inline-block;
        text-decoration: none;
        outline: none !important;
        transition-property: all;
        transition-duration: 500ms;

        &::-moz-focus-inner {
            border: 0 !important;
        }

        &:hover, &:focus {
            text-decoration: none;
            color: #000000;
        }
    }

    ul {
        list-style: none;

        li {
            outline: none;
        }
    }

    .container {
        width: 1200px;

        @include breakpoint.breakpoint(medium) {
            width: 970px;
        }


        @include breakpoint.breakpoint(small) {
            width: 750px;
        }


        @include breakpoint.breakpoint(extra-small) {
            width: 100%;
        }
    }
}