@use "../mixins/breakpoint";

header {
    position: fixed;
    z-index: 1;
    width: 100%;
    padding-left: 25px;
    padding-right: 25px;
    transition-duration: 400ms;
    transition-property: background-color;

    &.menu-scroll {
        background-color: #fff;
        box-shadow: rgba(0, 0, 0, 0.13) 0 4px 16px;
        transition-duration: 400ms;
        transition-property: background-color;

        .navbar-default {
            .navbar-collapse {
                .navbar-nav {
                    &.languages {
                        li {
                            &.active {
                                a {
                                    color: #000;

                                    &:hover {
                                        color: #000;
                                    }
                                }
                            }

                            a {
                                &:hover {
                                    color: #000;
                                }
                            }
                        }
                    }

                    li {
                        &.active {
                            a {
                                background-color: transparent;
                                color: #ecd78c;
                            }
                        }

                        &.open {
                            .dropdown-menu {
                                background-color: #fff;

                                li {
                                    display: block;
                                    line-height: 30px;

                                    &:hover, &:focus {
                                        a {
                                            font-size: 11px;

                                            &:hover, &:focus {
                                                color: #ecd78c;
                                            }
                                        }
                                    }

                                    a {
                                        font-size: 11px;

                                        &:hover, &:focus {
                                            color: #ecd78c;
                                        }
                                    }
                                }
                            }
                        }

                        &.dropdown {
                            &:hover {
                                .dropdown-menu {
                                    display: block;
                                    background-color: #fff;
                                }
                            }
                        }

                        a {
                            color: #000;
                        }
                    }
                }
            }
        }
    }

    .navbar-default {
        background: transparent;
        margin-bottom: 0;
        border: 0;
        border-radius: 0;

        .navbar-header {
            .navbar-brand {
                height: 100px;
                margin-left: 0;
                padding: 0;
                line-height: 100px;

                img {
                    display: inline-block;
                    max-width: 200px;
                    max-height: 63px;
                    line-height: 100px;
                    vertical-align: middle;
                }
            }
        }

        .navbar-collapse {
            float: right;
            border: none;

            .navbar-nav {
                height: 100px;
                margin-left: 40px;

                &.languages {
                    li {
                        margin-left: 15px;

                        a {
                            font-family: "Arial";

                            &:hover {
                                color: #fff;
                            }
                        }

                        &.active {
                            a {
                                font-weight: 300;
                                opacity: 0.5;
                                color: #fff;

                                &:hover {
                                    color: #fff;
                                }
                            }
                        }
                    }
                }

                li {
                    position: relative;
                    display: inline-block;
                    margin-left: 22px;
                    line-height: 100px;

                    &.active {
                        a {
                            background-color: transparent;
                            color: #ecd78c;

                            &:hover, &:focus {
                                background-color: transparent;
                                color: #ecd78c;
                            }
                        }
                    }

                    &.open {
                        a {
                            background-color: transparent;

                            &:hover, &:focus {
                                background-color: transparent;
                                color: #ecd78c;
                            }
                        }

                        .dropdown-menu {
                            top: 75px;
                            background-color: transparent;

                            li {
                                display: block;
                                line-height: 30px;

                                &:hover, &:focus {
                                    a {
                                        font-size: 11px;

                                        &:hover, &:focus {
                                            color: #ecd78c;
                                        }
                                    }
                                }

                                a {
                                    font-size: 11px;

                                    &:hover, &:focus {
                                        color: #ecd78c;
                                    }
                                }
                            }
                        }
                    }

                    &.dropdown {
                        &:hover, &:focus {
                            background-color: transparent;

                            .dropdown-menu {
                                top: 75px;
                                display: block;
                                background-color: transparent;

                                li {
                                    display: block;
                                    line-height: 30px;

                                    a {
                                        font-size: 11px;

                                        &:hover, &:focus {
                                            background-color: transparent;
                                        }
                                    }
                                }
                            }
                        }

                        .dropdown-menu {
                            border-radius: 0px;
                        }

                        .fa-chevron-down {
                            display: none;
                        }
                    }

                    a {
                        display: inline-block;
                        padding: 0;
                        font-family: 'Roboto',sans-serif;
                        font-weight: 600;
                        font-size: 13px;
                        text-transform: uppercase;
                        text-decoration: none;
                        letter-spacing: 2px;
                        color: #fff;

                        &:hover, &:focus {
                            background-color: transparent;
                            color: #ecd78c;
                        }
                    }
                }
            }
        }
    }

    @include breakpoint.breakpoint(small) {
        padding: 0;

        .container-fluid {
            padding: 0;
        }

        &.menu-scroll {
            .navbar-default {
                .navbar-header {
                    .navbar-toggle {
                        border-radius: 0;
                        background-color: #000;

                        &.collapsed {
                            background-color: #000;
                        }
                    }
                }

                .navbar-collapse {
                    .navbar-nav {
                        &.languages {
                            li {
                                &.active {
                                    a {
                                        &:hover, &:focus {
                                            color: #000;
                                        }
                                    }
                                }

                                a {
                                    &:hover, &:focus {
                                        color: #000;
                                    }
                                }
                            }
                        }

                        li {
                            &.dropdown {
                                a:hover, a:focus {
                                    background-color: transparent;
                                    color: #ecd78c;
                                }

                                &.open {
                                    &:hover, &:focus {
                                        a:hover, a:focus, a:active {
                                            background-color: transparent;
                                            color: #ecd78c;
                                        }

                                        .dropdown-menu {
                                            display: block;
                                            padding: 0;
                                        }
                                    }

                                    .dropdown-menu {
                                        padding: 0;
                                        border: none;
                                        box-shadow: none;

                                        li {
                                            line-height: 45px;

                                            a {
                                                font-size: 18px;
                                            }
                                        }
                                    }
                                }

                                .dropdown-menu {
                                    position: static;
                                    padding: 0;
                                }
                            }
                        }
                    }
                }
            }
        }

        .navbar-default {
            .navbar-header {
                float: none;
                margin: 0;
                text-align: center;

                .navbar-toggle {
                    float: left;
                    display: block;
                    height: 54px;
                    width: 54px;
                    margin: 0;
                    padding: 18px 16px;
                    border: none;
                    border-radius: 0px;
                    background-color: #000;
                    transition-duration: 600ms;
                    transition-property: all;

                    &.collapsed {
                        background-color: transparent;
                        transition-duration: 600ms;
                        transition-property: all;

                        .icon-bar {
                            position: static;

                            &:nth-child(2), &:last-child {
                                position: static;
                                transform: rotate(0deg);
                            }

                            &:nth-child(3) {
                                width: 21px;
                            }
                        }
                    }

                    &:hover &:focus {
                        background-color: transparent;

                        .icon-bar {
                            opacity: 0.8;
                        }
                    }

                    .icon-bar {
                        position: fixed;
                        top: 24px;
                        width: 21px;
                        height: 3px;
                        margin-top: 0;
                        transition-property: all;
                        transition-duration: 500ms;
                        background-color: #fff;

                        &:not(:last-child) {
                            margin-bottom: 3px;
                        }

                        &:nth-child(2) {
                            position: fixed;
                            transform: rotate(45deg);
                        }

                        &:nth-child(3) {
                            width: 0;
                        }

                        &:last-child {
                            transform: rotate(-45deg);
                        }
                    }
                }

                .navbar-brand {
                    float: none;
                    height: 54px;
                    line-height: 54px;

                    img {
                        max-width: 152px;
                        max-height: 48px;
                    }
                }
            }

            .navbar-collapse {
                position: fixed;
                top: 54px;
                left: -100%;
                bottom: 0;
                height: auto !important;
                width: 100%;
                padding: 10vh 0;
                background-color: #fff;
                text-align: center;
                transition-duration: 250ms;
                transition-property: all;

                &.in {
                    left: 0;
                    bottom: 0;
                    z-index: -1;
                    display: block !important;
                    overflow-x: hidden;
                    transition-duration: 250ms;
                    transition-property: all;
                }

                .navbar-nav {
                    float: none !important;
                    height: auto;
                    margin: 0;

                    &.languages {
                        li {
                            display: inline-block;
                            margin-right: 8px;
                            margin-left: 8px;

                            &.active {
                                a {
                                    color: #000;

                                    &:hover, &:focus {
                                        color: #000;
                                    }
                                }
                            }

                            a {
                                font-size: 13px;

                                &:hover, &:focus {
                                    color: #000;
                                }
                            }
                        }
                    }

                    li {
                        float: none !important;
                        display: block;
                        margin-left: 0;
                        text-align: center;

                        &.dropdown {
                            &:hover, &:focus {
                                color: #ecd78c;

                                a {
                                    background-color: transparent;
                                    color: #ecd78c;
                                }

                                .dropdown-menu {
                                    display: block;
                                    padding: 0;

                                    li {
                                        line-height: 45px;

                                        a {
                                            font-size: 18px;
                                            color: #000;

                                            &:hover {
                                                color: #ecd78c;
                                            }
                                        }
                                    }
                                }
                            }

                            &.open {
                                .fa-chevron-down {
                                    transform: rotate(180deg);
                                    transition-duration: 400ms;
                                    transition-property: all;
                                }
                            }

                            a {
                                background-color: transparent;
                                color: #000;
                            }

                            .dropdown-menu {
                                position: static;
                                float: none;
                                display: block;
                                padding: 0;
                                border: none;
                                box-shadow: none;

                                li {
                                    line-height: 45px;

                                    a {
                                        font-size: 18px;

                                        &:hover, &:focus {
                                            color: #ecd78c;
                                        }
                                    }
                                }
                            }

                            .fa-chevron-down {
                                display: inline-block;
                                vertical-align: super;
                                margin-left: 10px;
                                cursor: pointer;
                                transition-duration: 400ms;
                                transition-property: all;
                            }
                        }

                        a {
                            font-size: 28px;
                            color: #000;
                        }
                    }
                }
            }
        }
    }


    @include breakpoint.breakpoint(extra-small) {
        &.menu-scroll {
            .navbar-default {
                .navbar-collapse {
                    .navbar-nav {
                        li {
                            &.dropdown {
                                &.open {
                                    .dropdown-menu {
                                        li {
                                            line-height: 30px;

                                            a {
                                                font-size: 16px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .navbar-default {
            .navbar-collapse {
                margin: 0;
                padding: 5vh 0;

                .navbar-nav {
                    li {
                        line-height: 75px;

                        &.dropdown {
                            &:hover, &:focus {
                                .dropdown-menu {
                                    li {
                                        line-height: 30px;

                                        a {
                                            font-size: 16px;
                                        }
                                    }
                                }
                            }

                            .dropdown-menu {
                                li {
                                    line-height: 30px;

                                    a {
                                        padding: 0;
                                        font-size: 16px;
                                        color: #000;
                                    }
                                }
                            }
                        }

                        a {
                            font-size: 22px;
                        }
                    }
                }
            }
        }
    }


    @include breakpoint.breakpoint(mobile) {
        &.menu-scroll {
            .navbar-default {
                .navbar-collapse {
                    .navbar-nav {
                        li {
                            &.dropdown {
                                &.open {
                                    .dropdown-menu {
                                        li {
                                            line-height: 21px;

                                            a {
                                                font-size: 12px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .navbar-default {
            .navbar-collapse {
                padding: 2vh 0;

                .navbar-nav {
                    li {
                        line-height: 60px;

                        &.dropdown {
                            &:hover, &:focus {
                                .dropdown-menu {
                                    li {
                                        line-height: 21px;

                                        a {
                                            font-size: 12px;
                                        }
                                    }
                                }
                            }

                            .dropdown-menu {
                                li {
                                    line-height: 21px;

                                    a {
                                        font-size: 12px;
                                    }
                                }
                            }
                        }

                        a {
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }


    @include breakpoint.breakpoint(mobile-landscape) {
        &.menu-scroll {
            .navbar-default {
                .navbar-collapse {
                    .navbar-nav {
                        li {
                            &.dropdown {
                                &.open {
                                    .dropdown-menu {
                                        li {
                                            line-height: 21px;

                                            a {
                                                font-size: 12px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .navbar-default {
            .navbar-collapse {
                padding: 1vh 0;

                .navbar-nav {
                    li {
                        line-height: 48px;

                        &.dropdown {
                            &:hover, &:focus {
                                .dropdown-menu {
                                    li {
                                        line-height: 21px;

                                        a {
                                            font-size: 12px;
                                        }
                                    }
                                }
                            }

                            .dropdown-menu {
                                li {
                                    line-height: 21px;

                                    a {
                                        font-size: 12px;
                                    }
                                }
                            }
                        }

                        a {
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }
}