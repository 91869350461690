.social {
    margin-bottom: 0;
    padding: 0;

    li {
        display: inline-block;

        a {
            font-size: 16px;

            i {
                font-size: 16px;
            }
        }

        &:not(:first-child) {
            a {
                margin-left: 10px;
            }
        }
    }
}