@use "../mixins/breakpoint";

.core-team {
    padding-top: 75px;
    padding-bottom: 60px;
    background-color: #fff;

    h3 {
        text-align: center;
        color: #1c1738;
    }

    p {
        max-width: 560px;
        margin: 0 auto;
        text-align: center;
        color: #000;
    }

    ul {
        margin-top: 100px;
        padding-left: 0;

        li {
            &:first-child {
                figure {
                    .photo, .enlarge {
                        background-image: url("../../img/alexandra-zhdankina-and-sotiris-toulias.jpg");
                    }
                }
            }

            &:nth-child(2) {
                 figure {
                     .photo, .enlarge {
                         background-image: url("../../img/marco-and-andrea.png");
                     }
                 }
            }

            &:last-child {
                figure {
                    .photo, .enlarge {
                        background-image: url("../../img/pavel-and-valentin.jpg");
                    }
                }
            }

            figure {
                position: relative;
                overflow: hidden;
                width: 100%;
                height: 450px;
                margin: 0 auto;

                &:hover, &:focus {
                    .photo {
                        animation-name: scale;
                        animation-timing-function: linear;
                        transform-origin: 50% 50%;
                        transform: scale(1.05);
                    }

                    .enlarge {
                        width: 390px;
                        height: 470px;
                    }

                    figcaption {
                        opacity: 1;

                        .position-wrapper {
                            transform: translateY(0);
                            opacity: 1;
                        }
                    }
                }

                .photo, .enlarge {
                    width: 100%;
                    height: 100%;
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center center;
                    transition-property: all;
                    transition-duration: 400ms;
                    transform: translate3d(0, 0, 0);
                }

                figcaption {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 100%;
                    background-image: linear-gradient(to bottom, rgba(28, 23, 56, 0.1), rgba(28, 23, 56, 0.8));
                    margin: 0 auto;
                    opacity: 0;
                    transition-property: all;
                    transition-duration: 450ms;

                    .position-wrapper {
                        position: absolute;
                        bottom: 0;
                        padding: 30px 40px;
                        opacity: 0;
                        transition-property: all;
                        transition-duration: 450ms;
                        transform: translateY(20%);

                        h5 {
                            &.second-employee {
                                margin-top: 20px;
                            }
                        }

                        span {
                            font-size: 14px;
                            font-weight: 400;
                            color: #ffffff;
                        }
                    }
                }
            }
        }
    }

    @include breakpoint.breakpoint(medium) {
        padding-bottom: 70px;

        ul {
            li {
                figure {
                    height: 400px;

                    &:hover, &:focus {
                        .enlarge {
                            width: 313px;
                            height: 420px;
                        }
                    }

                    figcaption {
                        .position-wrapper {
                            span {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }
    }


    @include breakpoint.breakpoint(small) {
        h3 {
            margin-bottom: 12px;
        }

        ul {
            margin-top: 45px;

            li {
                &:nth-child(-n+2) {
                    margin-bottom: 20px;
                }

                figure {
                    height: 400px;

                    &:hover, &:focus {
                        .enlarge {
                            width: 235px;
                            height: 315px;
                        }
                    }

                    figcaption {
                        .position-wrapper {
                            padding: 30px;

                            h5 {
                                font-size: 14px;

                                &.second-employee {
                                    margin-top: 10px;
                                }
                            }

                            span {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }
    }


    @include breakpoint.breakpoint(extra-small) {
        padding-top: 40px;
        padding-bottom: 20px;

        ul {
            li {
                margin-bottom: 40px;

                figure {
                    width: 350px;
                    height: 420px;
                    margin: 0 auto;

                    &:hover, &:focus {
                        .enlarge {
                            width: 370px;
                            height: 440px;
                        }
                    }

                    figcaption {
                        .position-wrapper {
                            h5 {
                                font-size: 16px;

                                &.second-employee {
                                    margin-top: 15px;
                                }
                            }

                            span {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
    }


    @include breakpoint.breakpoint(mobile-landscape) {
        ul {
            li {
                figure {
                    width: 225px;
                    height: 250px;

                    &:hover, &:focus {
                        .enlarge {
                            width: 235px;
                            height: 270px;
                        }
                    }

                    figcaption {
                        .position-wrapper {
                            h5 {
                                font-size: 13px;
                            }

                            span {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }
    }


    @include breakpoint.breakpoint(mobile) {
        padding-top: 20px;
        padding-bottom: 0;

        ul {
            margin-top: 30px;

            li {
                margin-bottom: 20px;

                figure {
                    width: 280px;
                    height: 350px;

                    &:hover, &:focus {
                        .enlarge {
                            width: 300px;
                            height: 370px;
                        }
                    }

                    figcaption {
                        .position-wrapper {
                            h5 {
                                font-size: 14px;

                                &.second-employee {
                                    margin-top: 10px;
                                }
                            }

                            span {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }
    }
}