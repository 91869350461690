@use "../mixins/breakpoint";

.about-cyprus {
    position: relative;
    height: 100vh;

    .cover {
        position: absolute;
        z-index: -1;
        overflow: hidden;
        width: 100%;
        height: 100vh;
        background-image: linear-gradient(to bottom, rgba(28, 23, 56, 1), rgba(28, 23, 56, 0.1) 100%), url("../../img/mapcyprus.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        transform: translateY(0);
    }

    .wrapper {
        position: absolute;
        bottom: 0;
        right: 20px;

        .cover-description {
            width: 50%;
            margin-left: 50%;
            margin-bottom: 120px;
            padding: 20px;
            background-color: #2A224C;
            text-align: left;

            h3 {
                margin-bottom: 20px;
            }
        }
    }

    @include breakpoint.breakpoint(small) {
        .wrapper {
        	right: 0;
            margin-left: 20px;
            margin-right: 20px;

            .cover-description {}
        }
    }


    @include breakpoint.breakpoint(extra-small) {
        .wrapper {
            .cover-description {
                width: 90%;
                margin-left: auto;
                margin-right: auto;

                h3 {
                    margin-top: 0;
                }
            }
        }
    }


    @include breakpoint.breakpoint(mobile-landscape) {
        .wrapper {
            bottom: 10%;
            left: 50%;
            width: 90%;
            margin: 0;
            transform: translateX(-50%);

            .cover-description {
                margin: 0;
                width: 100%;
            }
        }
    }
}