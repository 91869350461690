@use "../mixins/breakpoint";

.by-tailoring {
    background-color: #2a224c;

    .row {
        position: relative;

        .image {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            background-image: url("../../img/by-tailoring.png");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;

            img {
                display: none;
            }
        }

        .info {
            .text-wrapper {
                margin: 120px 50px;

                h3 {
                    margin-bottom: 12px;
                }

                p {
                    font-weight: 100;
                }
            }
        }
    }

    @include breakpoint.breakpoint(medium) {
        .row {
            .info {
                .text-wrapper {
                    margin: 70px 34px;
                }
            }
        }
    }


    @include breakpoint.breakpoint(small) {
        .row {
            .image {
                position: static;
                margin-top: 40px;
                background-image: none;

                img {
                    display: block;
                    margin: 0 auto;
                }
            }

            .info {
                .text-wrapper {
                    margin: 80px 60px;
                }
            }
        }
    }


    @include breakpoint.breakpoint(extra-small) {
        .row {
            .image {
                padding: 0;

                img {
                    width: 100%;
                }
            }

            .info {
                .text-wrapper {
                    margin: 60px 5px;
                }
            }
        }
    }


    @include breakpoint.breakpoint(mobile) {
        .row {
            .image {
                margin-top: 30px;
            }

            .info {
                .text-wrapper {
                    margin: 40px 5px;
                }
            }
        }
    }
}