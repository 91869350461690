@use "../base";
@use "../mixins/breakpoint";

footer {
    background-color: #2a224c;
    padding-top: 60px;
    color: #9da5b9;
    letter-spacing: 0.5;
    font-family: base.$baseFont;

    p {
        margin-bottom: 0;
        font-family: 'Raleway', sans-serif;
        font-size: 14px;
        color: inherit;
    }

    .registration-number {}

    .input-wrapper {
        margin-top: 32px;
        position: relative;

        .search_form_but {
            position: absolute;
            bottom: 2px;
            left: 22px;
            width: 40px;
            height: 44px;
            padding: 0;
            border: none;
            outline: none;
            background: transparent;
            cursor: pointer;
            -webkit-appearance: button;

            i {
                font-size: 22px;
                color: #3e4453;
                padding: 2px 4px 0px 0px;
            }
        }

        .form-control {
            height: auto;
            padding-top: 14px;
            padding-left: 48px;
            padding-bottom: 14px;
            outline: none !important;
            border: none;
            box-shadow: none;
            border-radius: 2px;
            background-color: #9DA5B9;
            color: #3e4453;
        }

        input::-webkit-input-placeholder {
            color: #3e4453  !important;
        }

        &:focus {
            border-color: transparent;
            box-shadow: none;
            border: none;
        }
    }

    ul.contacts {
        padding-left: 0;
        margin-bottom: 40px;

        li {
            &:before {
                color: inherit;
                display: inline-block;
                font-family: fontAwesome;
                margin-right: 10px;
                font-size: 14px;
            }

            &:not(:last-child) {
                margin-bottom: 10px;
            }

            a {
                color: inherit;

                &:hover {
                    color: #d4bb68;
                }
            }
        }

        li:nth-child(1) {
            &:before {
                content: "\f041";
            }
        }

        li:nth-child(2), li:nth-child(3) {
            &:before {
                content: "\f095";
            }
        }

        li:nth-child(4) {
            &:before {
                content: "\f003";
            }
        }

        li:nth-child(5) {
            &:before {
                content: "\f108";
            }
        }
    }

    ul.social-links {
        padding-left: 0;
        margin-bottom: 50px;

        li {
            display: inline-block;

            &:not(:last-child) {
                margin-right: 7px;
            }

            a {
                color: inherit;
                display: block;
                height: 45px;
                width: 45px;
                background-color: #1c1738;
                border-radius: 50%;
                font-size: 20px;
                text-align: center;
                line-height: 2.2;

                &:hover {
                    background-color: #d4bb68;
                }
            }
        }
    }

    .additional-info {
        padding-top: 15px;
        padding-bottom: 15px;
        background-color: #1c1738;

        p {
            padding: 0;
            font-family: 'Raleway', sans-serif;

            a {
                color: inherit;

                &:hover {
                    color: #d4bb68;
                }

                img {
                    max-width: 100px;
                    vertical-align: baseline;
                }
            }
        }

        a {
            font-family: 'Raleway', sans-serif;
            line-height: 25px;
            color: inherit;

            &:not(:last-child) {
                margin-right: 25px;
            }
        }
    }

    @include breakpoint.breakpoint(small) {
        .container {
            width: 100%;
            padding-left: 20px;
            padding-right: 20px;
        }

        .input-wrapper {
            margin-top: 105px;
            margin-bottom: 60px;
        }

        .additional-info {
            p {
                a {
                    margin-top: 0;
                }
            }

            a {
                //line-height: 25px;

                &:not(:last-child) {
                    margin-right: 5px;
                }
            }
        }
    }
}