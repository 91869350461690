@use "../mixins/breakpoint";

.our-partners {
    padding-top: 45px;
    padding-bottom: 60px;
    background-color: #fff;

    h3 {
        margin-bottom: 105px;
        text-align: center;
        color: #1c1738;
    }

    .partners-slider {
        position: relative;
        padding-left: 0;

        .slick-list {
            z-index: 0;
        }

        .slick-arrow {
            position: absolute;
            top: 50%;
            width: 20px;
            height: 28px;
            z-index: 0;
            padding: 5px;
            border: none;
            outline: none;
            background-color: transparent;
            transition-property: all;
            transition-duration: 250ms;

            &:hover, &:focus {
                border-color: rgba(#000, 0.6);
                opacity: 0.7;
            }

            &::before {
                position: absolute;
                top: 18px;
                left: 5px;
                content: '';
                display: block;
                width: 12px;
                height: 1px;
                background-color: #000;
                transform: rotate(45deg);
            }

            &::after {
                position: absolute;
                top: 10px;
                left: 5px;
                content: '';
                display: block;
                width: 12px;
                height: 1px;
                background-color: #000;
                transform: rotate(-45deg);
            }
        }

        .slick-prev {
            left: 0px;
        }

        .slick-next {
            right: 0px;
            transform: rotate(180deg);
        }

        li {
            height: 150px;
            padding: 20px;

            a {
                display: block;

                img {
                    filter: grayscale(100%);
                    -ms-filter: grayscale(100%);
                    transition-property: all;
                    transition-duration: 250ms;
                }

                &:hover, &:focus {
                    img {
                        filter: grayscale(0%);
                        -ms-filter: grayscale(0%);
                    }
                }
            }
        }
    }

    @include breakpoint.breakpoint(medium) {
        h3 {
            margin-bottom: 50px;
        }

        .partners-slider {
            padding-left: 20px;
            padding-right: 20px;

            .slick-arrow {
                top: 35%;
            }
        }
    }


    @include breakpoint.breakpoint(small) {
        h3 {
            margin-bottom: 30px;
        }

        .partners-slider {
            .slick-arrow {
                top: 40%;
            }

            li {
                height: 100px;
            }
        }
    }


    @include breakpoint.breakpoint(extra-small) {
        padding-top: 10px;
        padding-bottom: 30px;

        h3 {
            margin-bottom: 45px;
        }

        .partners-slider {
            padding-left: 30px;
            padding-right: 30px;

            .slick-arrow {
                top: 25%;
            }

            .slick-prev {
                left: 10px;
            }

            .slick-next {
                right: 10px;
            }

            li {
                height: auto;
                padding: 0;

                a {
                    img {
                        width: 256px;
                        margin: 0 auto;
                    }
                }
            }
        }
    }


    @include breakpoint.breakpoint(mobile) {
        padding-top: 0;
        padding-bottom: 20px;

        h3 {
            margin-bottom: 20px;
        }
    }
}